export const createMarkup = (value: string) => {
  if (value.includes('\\frac{') && value.includes('}{')) {
    const fracPattern = /\\frac\{([\d]+)\}{([\d]+)\}/g;
    const fracSubstring = value.match(fracPattern);
    if (fracSubstring) {
      fracSubstring.forEach((substring) => {
        const curlyBracketsPattern = /[^{\}]+(?=})/g;
        const numerator = substring.match(curlyBracketsPattern)![0];
        const denominator = substring.match(curlyBracketsPattern)![1];
        value = value.replace(
          substring,
          '<span style="display: inline-block;position: relative;vertical-align: middle;letter-spacing: 0.001em;text-align: center;"><span style="display: block;padding: 0.1em;">' +
            numerator +
            '</span><span style="display: block;padding: 0.1em;border-top: thin solid black;">' +
            denominator +
            '</span></span>\n'
        );
      });
    }
  }
  return { __html: value };
};
