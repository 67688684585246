import { FC, useState } from 'react';
import {
  Alert,
  Button,
  Center,
  Heading,
  HStack,
  Modal,
  Spinner,
  Text,
  useTheme,
  useToast,
  VStack,
  Box,
} from 'native-base';
import {
  IQuizLearningObjective,
  IQuizWithUserAnswerCreateRequest,
} from '../types/QuizTypes';
import { IQuestionWithUserAnswer } from '../types/QuestionTypes';
import { getQuizQuestions, submitQuizAnswers, submitQuizHighQuestionsAnswers, getQuizReport } from '../api';
import { useMutation, useQuery } from 'react-query';
import QuizQuestion from './QuizQuestion';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import qiuiz_result_background from '../images/qiuiz_result_background.png';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { HiOutlineEmojiSad, HiOutlineEmojiHappy } from 'react-icons/hi';
import { FaStar, FaRegStar } from 'react-icons/fa';
import QuestionNumberButton from './QuestionNumberButton';
import { useTranslation } from 'react-i18next';
import useInterval from 'react-useinterval';
import moment from 'moment';
import { useHistory } from 'react-router';
import { getLearningMaterialsByStudentRole } from '../api/LearningMaterial'
import QuizReportModal from './QuizReportModal';


interface QuizModalProps {
  onClose: () => void;
}

type PropsType = QuizModalProps & IQuizLearningObjective;

const QuizModal: FC<PropsType> = (props) => {
  const toast = useToast();
  const history = useHistory()

  const { colors } = useTheme();
  const { t, i18n } = useTranslation()

  const [startTime, setStartTime] = useState<Date>();
  const [countTime, setCountTime] = useState<Date>();
  const [elaspedTime, setElaspedTime] = useState<string>();
  const [questions, setQuestions] = useState<IQuestionWithUserAnswer[]>([]);
  const [highQuestions, setHighQuestions] = useState<IQuestionWithUserAnswer[]>([]);
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState<number>(0);
  const [largestVisitedQuestionNumber, setLargestVisitedQuestionNumber] =
    useState<number>(0);
  const [quizId, setQuizId] = useState<string>();
  const [score, setScore] = useState<number>(-1);
  const [bonus, setBonus] = useState<number>(-1);
  const [error, setError] = useState<string>('');
  const [canTryHighLevelQuestion, setCanTryHighLevelQuestion] = useState<boolean>(false);
  const [isShowQuizReport, setIsShowQuizReport] = useState<boolean>(false);
  const [quizReportData, setQuizReportData] = useState<any>();

  const { data: learningMaterials } = useQuery(['learningMaterials', props._id], () => getLearningMaterialsByStudentRole(props._id));

  const query = useQuery({
    queryKey: [`questions-${props._id}`],
    queryFn: () => getQuizQuestions(props._id),
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const formattedQuestions = [...data].filter(x => x.level !== 'high').map((item) => ({
        ...item,
        questionOptions: item.questionOptions?.map((option) => String(option)),
        userAnswer: '',
        isAttempted: false,
      }));

      const formattedHighQuestions = [...data].filter(x => x.level === 'high').map((item) => ({
        ...item,
        questionOptions: item.questionOptions?.map((option) => String(option)),
        userAnswer: '',
        isAttempted: false,
      }));

      setStartTime(new Date());
      setCountTime(new Date());
      setQuestions(formattedQuestions);
      setHighQuestions(formattedHighQuestions);
    },
    onError: () => {
      toast.show({
        title: 'Something went wrong',
        status: 'error',
        description: 'Cannot fetch questions',
      });
    },
  });

  const { data: quizReport, refetch, isFetching } = useQuery(['quizReport', quizId], () => getQuizReport(quizId));

  useInterval(() => { 
    if (startTime !== undefined) {
      const elapsedTime = new Date().getTime() - startTime.getTime();
      let elapsedTimeDisplay = ''
      if (elapsedTime / 1000 / 60 / 60 > 1) {
        elapsedTimeDisplay = moment.utc(elapsedTime).format('HH:mm:ss');
      } else {
        elapsedTimeDisplay = moment.utc(elapsedTime).format('mm:ss');
      }
      setElaspedTime(elapsedTimeDisplay)
    }
  }, startTime !== undefined && (score === -1 || (canTryHighLevelQuestion && bonus === -1)) ? 500 : null);

  const mutation = useMutation(submitQuizAnswers, {
    onSuccess: (data) => {
      setScore(data.score);
      setQuizId(data.quizId);
    },
    onError: (err: any) => {
      setError(err.message);
    },
  });

  const changeIsAttempted = (index: number) => {
    const updatedQuestions = [...questions];
    const currentQuestion = updatedQuestions[index];
    if (!currentQuestion.isAttempted && currentQuestion.userAnswer !== "") {
      currentQuestion!.isAttempted = true;
    } else if (currentQuestion.isAttempted && countTime !== undefined) {
      const _reVisitTime = new Date();
      const reVisitTimeMs = _reVisitTime.getTime() - countTime.getTime();
      if (!currentQuestion!.reVisitTimeMs) {
        currentQuestion!.reVisitTimeMs = reVisitTimeMs;
      } else {
        currentQuestion!.reVisitTimeMs += reVisitTimeMs;
      }
    }
    setQuestions(updatedQuestions);
  }

  const handleAnswerOnChange = (value: string, questionId: string) => {
    const updatedQuestions = [...questions];
    const currentQuestion = updatedQuestions.find(
      (question) => question._id === questionId
    );
    if (!currentQuestion?.isAttempted && countTime !== undefined) {
      const _responseTime = new Date();
      const responseTimeMs = _responseTime.getTime() - countTime.getTime();
      currentQuestion!.responseTimeMs = responseTimeMs;
    }
    if (currentQuestion?.isAttempted && !currentQuestion!.oldUserAnswer) {
      currentQuestion!.oldUserAnswer = currentQuestion!.userAnswer
    }
    currentQuestion!.userAnswer = value;
    setQuestions(updatedQuestions);
  };

  const handleQuizReportOnPress = () => {
    setQuizReportData(quizReport);
    setIsShowQuizReport(true);
  }

  const handleQuizReportModalOnClose = () => {
    setIsShowQuizReport(false);
  }

  const handleSubmit = async () => {
    let submitQuestions = questions;
    // Remove the isAttempted for sending to backend
    submitQuestions.forEach((question) => {
      delete question.isAttempted
      return question;
    })
    if (!canTryHighLevelQuestion) {
      if (startTime) {
        const endTime = new Date();
        const elapsedTimeMs = endTime.getTime() - startTime.getTime();

        const quizWithUserAnswerCreateRequest: IQuizWithUserAnswerCreateRequest =
        {
          startTime: startTime,
          endTime: endTime,
          elapsedTimeMs: elapsedTimeMs,
          questions: submitQuestions,
        };

        mutation.mutate(quizWithUserAnswerCreateRequest);
      } else {
        console.error(
          'Start time is not set, which should not be the case for normal usecase'
        );
      }

    } else {
      if (quizId !== undefined && startTime !== undefined) {
        const _endTime = new Date();
        const _elapsedTimeMs = _endTime.getTime() - startTime.getTime();
        const quizWithHighAnswerCreateRequest: IQuizWithUserAnswerCreateRequest =
        {
          startTime: startTime,
          endTime: _endTime,
          elapsedTimeMs: _elapsedTimeMs,
          questions: submitQuestions,
        };
        const result = await submitQuizHighQuestionsAnswers(quizId, quizWithHighAnswerCreateRequest)
        await refetch()
        setBonus(result.bonus)
      }
    }
  };

  return (
    <>
    { isShowQuizReport && 
      <QuizReportModal
        onClose={handleQuizReportModalOnClose}
        {...quizReportData}
      />
    }
    <Modal
      isOpen
      onClose={props.onClose}
      size="full"
      closeOnOverlayClick={false}
      _backdrop={{ height: '100%' }}
    >
      <Modal.Content height="100%" maxHeight="100%" display={'flex'}>
        <Modal.Header background="primary.500"
          display={'flex'}
          flexDirection={['column', 'column', 'row']}
          alignItems={['flex-start', 'flex-start', 'center']}
          justifyContent={['space-between']}
        >
          <Box>
            <Text fontSize={'16px'} fontWeight={'bold'}>
              {props.code} {i18n.language === 'zh' && props.nameChi !== '' ? props.nameChi : props.nameEng}
            </Text>
          </Box>
          <Box alignSelf={'flex-end'}>
            {`${t('Time used')}: ${elaspedTime}`}
          </Box>
        </Modal.Header>
        <Box flex={1} overflow="auto">
          {query.isFetching || mutation.isLoading ? (
            <Center flex={1} px="3" w="full" h="full">
              <Spinner size="lg" />
            </Center>
          ) : (
            <>
              {score === -1 || (canTryHighLevelQuestion && bonus === -1) ? (
                <>
                  {questions.length === 0 ? (
                    <Center
                      flex={1}
                      px="3"
                      w="full"
                      h="full"
                      textAlign="center"
                    >
                      <Text fontSize="md" my={5}>
                        {t('No questions')}
                      </Text>
                      <Button variant="outline" onPress={props.onClose}>
                        {t('Go back to learning objective')}
                      </Button>
                    </Center>
                  ) : (
                    <>
                      <HStack width={'100%'} flexWrap={'wrap'}>
                        {questions.map((question, index) => (
                          <QuestionNumberButton
                            disabled={canTryHighLevelQuestion && question.level !== 'high'}
                            key={index}
                            questionNumber={index + 1}
                            isCurrent={index === currentQuestionNumber}
                            isAnswered={index <= largestVisitedQuestionNumber}
                            onClick={() => {
                              changeIsAttempted(currentQuestionNumber);
                              setCurrentQuestionNumber(index);
                              setCountTime(new Date());
                              if (index > largestVisitedQuestionNumber) {
                                setLargestVisitedQuestionNumber(index);
                              }
                            }}
                          />
                        ))}
                      </HStack>
                      <QuizQuestion
                        question={questions[currentQuestionNumber]}
                        currentQuestionNumber={currentQuestionNumber}
                        answerOnChange={handleAnswerOnChange}
                      />
                    </>
                  )}
                </>
              ) : (
                <Box
                  flex={1}
                  px="3"
                  w="full"
                  h="full"
                >
                  <div
                    style={{
                      backgroundImage: `url(${qiuiz_result_background})`,
                      backgroundSize: '100% 100%',
                      backgroundRepeat: 'no-repeat',
                      overflow: 'hidden',
                      zIndex: -1,
                      position: 'absolute',
                      top: 0,
                      bottom: 0,
                      right: 0,
                      left: 0,
                    }}
                  />
                  {canTryHighLevelQuestion === false &&
                    <VStack alignItems={'center'} paddingTop={'20px'}>
                      <Heading fontSize={'25'}>{t('Completed')}!</Heading>
                      {score >= 0.7 ? <HiOutlineEmojiHappy
                        size="300"
                        color={colors.primary['700']}
                      />
                        : <HiOutlineEmojiSad
                          size="300"
                          color={colors.primary['700']}
                        />}
                      <Text fontSize="30"
                        color={score >= 0.7 ? colors.success['500'] : colors.error['500']}
                        textAlign={'center'}
                      >
                        {t('Score')}: {Math.round(score * 100)}
                      </Text>
                      <Text fontSize="24" textAlign={'center'}>
                        {score >= 0.7 ?
                          t('Good job! You pass the test! Next unit is unlocked.')
                          :
                          t('You have to get > 70% on each learning objective to get to the next unit! Please try again!')
                        }
                      </Text>
                      <Button
                        marginTop={2}
                        variant="quizNext"
                        w='100px'
                        isLoading={isFetching}
                        onPress={handleQuizReportOnPress}
                      >
                        {t('Quiz Report')}
                      </Button>
                    </VStack>
                  }
                  {score >= 0.7 && canTryHighLevelQuestion === false && highQuestions.length > 0 &&
                    <Center marginTop={3}>
                      <Text fontSize="24">
                        {t('Do you want to try high level questions to score bonus points?')}
                      </Text>
                      <Button
                        marginTop={2}
                        variant="quizNext"
                        w='100px'
                        onPress={() => {
                          setCanTryHighLevelQuestion(true)
                          setCurrentQuestionNumber(questions.length);
                          setQuestions([...questions, ...highQuestions]);
                        }}
                      >
                        {t('Yes')}
                      </Button>
                    </Center>
                  }

                  {bonus !== -1 &&
                    <>
                      <Heading fontSize={'25'}>{t('Completed')}!</Heading>
                      <Box marginTop={3} justifyContent={'center'} alignItems={'center'} flex={1}>
                        {bonus > 0 ?
                          <HStack space={1}>
                            {[...Array(bonus)].map(_ => (
                              <Box height={['80px', '120px', '120px', '300px']}>
                                <FaStar
                                  style={{ height: '100%', width: '100%' }}
                                  color={colors.primary['700']}
                                />
                              </Box>
                            ))}
                          </HStack>
                          : <FaRegStar
                            size="200"
                            color={'#c7c5c57b'}
                          />}
                        {bonus > 0 ?
                          <Text textAlign={'center'} fontSize="30" color={colors.success['500']}>{`${t('Well done!')} ${t('You get')} ${bonus} ${bonus > 1 ? t('bonus stars') : t('bonus star')}!`}</Text>
                          :
                          <Text textAlign={'center'} fontSize="24">
                            {t('Never mind! You already did a very good job!')}
                          </Text>
                        }
                        <Button
                          marginTop={2}
                          variant="quizNext"
                          w='100px'
                          isLoading={isFetching}
                          onPress={handleQuizReportOnPress}
                        >
                          {t('Quiz Report')}
                        </Button>
                      </Box>
                    </>
                  }
                  {learningMaterials && learningMaterials.length > 0 &&
                    <>
                      <Center marginTop={3}>
                        <Text fontSize="24">
                          {t('You can read the teaching materials to learn more')}
                        </Text>
                        <Button
                          marginTop={2}
                          variant="quizNext"
                          w='250px'
                          _text={{ color: 'black' }}
                          onPress={() => {
                            history.push(`/learningmaterials?learningObjectiveId=${props._id}`)
                          }}
                        >
                          {t('Go to learning material')}
                        </Button>
                      </Center>
                    </>
                  }

                </Box>
              )}
              {error && (
                <Alert w="100%" status="error">
                  <VStack space={2} flexShrink={1} w="100%">
                    <HStack
                      flexShrink={1}
                      space={2}
                      justifyContent="space-between"
                    >
                      <HStack
                        w="full"
                        space={2}
                        flexShrink={1}
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <HStack space={2} flexShrink={1} alignItems="center">
                          <Alert.Icon />
                          <Text fontSize="sm" color="coolGray.800">
                            {error}
                          </Text>
                        </HStack>
                        <Button
                          variant="outline"
                          colorScheme="error"
                          onPress={props.onClose}
                        >
                          {t('Go back to learning objective')}
                        </Button>
                      </HStack>
                    </HStack>
                  </VStack>
                </Alert>
              )}
            </>
          )}
        </Box>
        {!query.isFetching && questions[0]?.userAnswer && (
          <Modal.Footer alignItems="center" justifyContent="center">
            <HStack
              alignItems="center"
              justifyContent={
                currentQuestionNumber > 0 &&
                  (!canTryHighLevelQuestion || (canTryHighLevelQuestion && questions[currentQuestionNumber - 1].level === 'high')) &&
                  currentQuestionNumber <= questions.length - 1
                  ? 'space-between'
                  : 'flex-end'
              }
              w="full"
              space={3}
            >
              {score === -1 || (canTryHighLevelQuestion && bonus === -1) ? (
                <>
                  {currentQuestionNumber > 0 && (!canTryHighLevelQuestion || (canTryHighLevelQuestion && questions[currentQuestionNumber - 1].level === 'high')) && (
                    <Button
                      size="lg"
                      width="30%"
                      variant="quizNext"
                      startIcon={<IoIosArrowBack size={20} color="white" />}
                      onPress={() => {
                        setCurrentQuestionNumber(currentQuestionNumber - 1);
                        setCountTime(new Date());
                      }
                      }
                    >
                      {t('Previous')}
                    </Button>
                  )}
                  {currentQuestionNumber < questions.length - 1 ? (
                    <>
                      {questions[currentQuestionNumber]?.userAnswer && (
                        <Button
                          width="30%"
                          size="lg"
                          variant="quizNext"
                          endIcon={
                            <IoIosArrowForward size={20} color="white" />
                          }
                          onPress={() => {
                            changeIsAttempted(currentQuestionNumber);
                            setCurrentQuestionNumber(currentQuestionNumber + 1);
                            setCountTime(new Date());
                            if (
                              currentQuestionNumber + 1 >
                              largestVisitedQuestionNumber
                            ) {
                              setLargestVisitedQuestionNumber(
                                currentQuestionNumber + 1
                              );
                            }
                          }}
                        >
                          {t('Next')}
                        </Button>
                      )}
                    </>
                  ) : (
                    <>
                      {questions[currentQuestionNumber]?.userAnswer && (
                        <Button
                          w="30%"
                          size="lg"
                          variant="quizNext"
                          onPress={() => {
                            changeIsAttempted(currentQuestionNumber);
                            handleSubmit();
                          }}
                          isDisabled={mutation.isLoading}
                        >
                          {t('Submit')}
                        </Button>
                      )}
                    </>
                  )}
                </>
              ) : (
                <Button
                  w="full"
                  variant="quizNext"
                  size="lg"
                  onPress={props.onClose}
                >
                  {t('Done')}
                </Button>
              )}
            </HStack>
          </Modal.Footer>
        )}
      </Modal.Content>
    </Modal >
    </>
  );
};

export default QuizModal;
