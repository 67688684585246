import moment from 'moment';
import { Box, Heading } from 'native-base';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoFileTray } from 'react-icons/io5';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { IQuiz } from '../types/QuizTypes';

interface StudentReportFrequencyBarChartProps {
  quizReports: IQuiz[];
}

const StudentReportFrequencyBarChart: React.FC<StudentReportFrequencyBarChartProps> = (props) => {
  const [data, setData] = useState<any[]>();
  const [dataKeySet, setDataKeySet] = useState<{ [key: string]: string }>();

  const { t, i18n } = useTranslation('translation');


  useEffect(() => {
    // group scores with its learning unit by date
    const dataGroupByDate = props.quizReports.sort((a, b) => (a.learningUnit.code < b.learningUnit.code ? -1 : 1)).reduce<{ [key: string]: string[] }>(
      (groups, item) => {
        const date = moment(item.createdAt).format('L');
        return {
          ...groups,
          [date]: [...groups[date] || [], item.learningUnit.code],
        }
      },
      {})

    console.log(dataGroupByDate)

    // filter out duplicated and non-max score for each learning unit for each date
    let formattedData: any = [];
    Object.entries(dataGroupByDate).forEach(([k, v]) => {
      let frequencyMap: any = {};
      frequencyMap = {
        'createdAt': k,
        'number': v.length,
      }
      formattedData.push(frequencyMap);
    });

    setData(formattedData.sort((a: any, b: any) => a.createdAt < b.createdAt ? -1 : 1));
    console.log(formattedData)

    //get all LU with quizzes and generate unique color for them
    const colorKeys: any = {};
    props.quizReports.map(x => x.learningUnit.code).filter((v, i, a) => a.indexOf(v) === i).forEach((code, i) => {
      colorKeys[code] = 'black';
    });
    console.log(Object.values(colorKeys))
    setDataKeySet(colorKeys);
  }, [props.quizReports]);

  return (
    <Box w={["100%", "100%", "50%"]} h="200px" mb={8}>
      <Heading>{t('No. of Test Done')}</Heading>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="createdAt" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="number" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default StudentReportFrequencyBarChart;
