import { group } from 'console';
import moment from 'moment';
import { Container, Box, Text, Heading } from 'native-base';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoFileTray } from 'react-icons/io5';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
} from 'recharts';
import { IQuiz } from '../types/QuizTypes';

interface StudentReportRadarChartProps {
  quizReports: IQuiz[];
}

const StudentReportRadarChart: React.FC<StudentReportRadarChartProps> = (props) => {
  const [radarChartData, setRadarChartData] = useState<any[]>();

  const { t, i18n } = useTranslation()

  useEffect(() => {

    const learningUnitDataReduced = props.quizReports.reduce<{ [key: string]: any[] }>((groups, item) => {
      const learningUnitArea = i18n.language === 'zh' && item.learningUnitArea.nameChi !== null ? item.learningUnitArea.nameChi : item.learningUnitArea.nameEng;
      return {
        ...groups, [learningUnitArea]: [
          ...(groups[learningUnitArea] || []), item.score * 100
        ]
      }
    }, {})

    console.log(learningUnitDataReduced)

    let formattedData: any = [];
    Object.entries(learningUnitDataReduced).forEach(([key, value]) => {
      formattedData.push({
        'learningUnitArea': key,
        'score': value.reduce((a, b) => a + b, 0) / value.length,
      })
    })

    setRadarChartData(formattedData)
  }, [props.quizReports, i18n.language])

  return (
    <Box w={["100%", "100%", "50%",]} h="200px" mb={8} alignItems={'center'}>
      <Heading>{t('Learning Unit Area')} (Strand)</Heading>
      <ResponsiveContainer width="100%" height="100%">
        <RadarChart cx="50%" cy="50%" outerRadius="80%" data={radarChartData}>
          <PolarGrid />
          <PolarAngleAxis dataKey="learningUnitArea" />
          <PolarRadiusAxis domain={[0, 100]} />
          <Radar name='student' dataKey="score" stroke="#d8bd84" fill="#f3a32b" fillOpacity={0.6} />
          <Label value="radarChartData" position="centerTop" />
        </RadarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default StudentReportRadarChart;
