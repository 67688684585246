import React, { useCallback, useEffect, useRef, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  Heading,
  Center,
  useToast,
  Text,
  HStack,
  useTheme,
  Box,
  ScrollView,
  Flex,
  Spinner,
  Button,
} from 'native-base';
import { tableIcons } from '../MaterialTableIcons';
import { useQuery } from 'react-query';
import { getLearningObjectivesByStudentRole, getLearningUnitsByStudentRole, getStudentQuizReports, getUserById } from '../api';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import QuizReportModal from '../components/QuizReportModal';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/reducers';
import { IUser } from '../types/AuthTypes';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import StudentReportLearningUnitBarChart from '../components/StudentReportLearningUnitBarChart';
import StudentReportLearningObjectiveBarChart from '../components/StudentReportLearningObjectiveBarChart';
import StudentReportRadarChart from '../components/StudentReportRadarChart';
import StudentReportFrequencyBarChart from '../components/StudentReportFrequencyBarChart';
import TeacherSummaryTab from '../components/TeacherSummaryTab';
import { IQuiz } from '../types/QuizTypes';
import MaterialTable, { Column } from '@material-table/core';
import { FaStar, FaRegStar } from 'react-icons/fa';
import Select from 'react-select';
import { getQuestions, getSubjects, deleteQuestion, updateQuestion, getLearningUnits, getLearningObjectives, getLearningUnitAreas, getStudentsBySchool } from '../api';
import { ExportCsv } from '@material-table/exporters';
import dayjs from 'dayjs';

const selectStyles = {
  container: (base: any, state: any) => ({
    ...base,
    margin: '0 0.5rem',
    width: '100%',
    zIndex: '999',
  }),
};

const StudentReportPage: React.FC<RouteComponentProps> = (props) => {
  const schoolId = useSelector((state: RootState) => state.auth.user?.userProfile.teacher?.school?._id ?? '');
  const toast = useToast();

  const { colors } = useTheme();

  const [queryUserId, setQueryUserId] = useState<string>();

  const { t, i18n } = useTranslation('translation');

  const authedUser = useSelector<RootState, IUser | null>(
    (state) => state.auth.user
  );
  const currentRole = useSelector<RootState, string | null>(
    (state) => state.auth.currentRole
  );

  const { data: students, isLoading: isLoadingStudents } = useQuery(['studentsBySchool', schoolId], () => getStudentsBySchool(schoolId), {
    refetchOnWindowFocus: false,
    enabled: currentRole !== 'student',
  });

  const [studentOptions, setStudentOptions] = useState<{ label: string, value: string }[] | undefined>();

  const [isShowSummary, setIsShowSummary] = useState<boolean>(false);

  const [summaryData, setSummaryData] = useState<IQuiz[]>();

  useEffect(() => {
    if (students) {
      const uniqueStudents = students.filter((value, index, self) => index === self.findIndex(t => t._id === value._id))
      const _studentOptions = uniqueStudents.map(user => (
        {
          label: i18n.language === 'zh' && user.lastNameChi && user.firstNameChi ? user.lastNameChi + user.firstNameChi : user.lastNameEng + user.firstNameEng,
          value: user._id,
        })).sort((a, b) => a.label.localeCompare(b.label))
      setStudentOptions(_studentOptions)
    }
  }, [students])

  useEffect(() => {
    if (currentRole === 'student') {
      setQueryUserId(authedUser?._id);
    } else {
      if (props.location.pathname.split('/')[2] !== undefined) {
        setQueryUserId(props.location.pathname.split('/')[2]);
        setFilters((prev: any) => {
          const selectedStudent = studentOptions?.find(x => x.value === props.location.pathname.split('/')[2])
          return { ...prev, student: selectedStudent }
        })
      } else {
        setQueryUserId('');
      }
    }
  }, [isLoadingStudents, students, studentOptions]);


  const [selectedReport, setSelectedReport] = useState<any>();

  const [quizReportData, setQuizReportData] = useState<IQuiz[]>([]);

  const [isShowOnlyTopScore, setIsShowOnlyTopScore] = useState<boolean>(true);

  const {
    data: studentQuizReportData,
    isLoading,
  } = useQuery(
    ['studentQuizReport', queryUserId],
    () => getStudentQuizReports(queryUserId),
    {
      enabled: queryUserId !== undefined,
      refetchOnWindowFocus: false,
      onError: () => {
        toast.show({
          title: 'Something went wrong',
          status: 'error',
          description: 'Cannot fetch students',
        });
      },
      onSuccess: (data) => {
        if (summaryData === undefined) {
          setSummaryData(data);
        }
      }
    }
  );


  useEffect(() => {
    if (studentQuizReportData !== undefined) {
      const _quizReportData = studentQuizReportData.reduce((acc: IQuiz[], cur: IQuiz) => {
        const added = acc.find(x => x.user._id === cur.user._id && x.learningObjective._id === cur.learningObjective._id)
        if (added) {
          if (added.score < cur.score) {
            const removed = acc.filter(x => x.user._id !== cur.user._id || x.learningObjective._id !== cur.learningObjective._id)
            return [...removed, cur];
          } else {
            return acc;
          }
        } else {
          return [...acc, cur];
        }
      }, []);

      setQuizReportData(_quizReportData);
    }
  }, [isShowOnlyTopScore, studentQuizReportData]);

  const [columnObject, setColumnObject] = useState<Column<IQuiz>[]>([]);

  const handleSelectOnChange = (name: string, newValue: any) => {
    setFilters({ ...filters, [name]: newValue });
  };

  const handleBackToReport = () => {
    setQueryUserId("");
    setIsShowSummary(false);
  }

  useEffect(() => {
    setColumnObject([
      {
        title: 'ID',
        type: 'string',
        field: '_id',
        hidden: true,
      },
      {
        title: t('Student'),
        type: 'string',
        field: 'user._id',
        customSort: (a, b) => (a.user.lastNameEng).localeCompare(b.user.lastNameEng),
        lookup: quizReportData?.reduce((map, obj) => (
          {
            ...map,
            [obj.user._id]: (i18n.language === 'zh' ? `${obj.user?.lastNameChi}${obj.user?.firstNameChi}` : `${obj.user?.lastNameEng} ${obj.user?.firstNameEng}`)
          }
        ), {}),
      },
      {
        title: t('Student Number'),
        type: 'string',
        field: 'user.userProfile.student.number',
        sorting: false,
      },
      {
        title: t('Grade'),
        type: 'string',
        field: 'user.userProfile.student.grade',
        lookup: quizReportData?.reduce((map, obj) => (
          {
            ...map,
            [(obj.user.userProfile.student?.grade) as number]: obj.user.userProfile.student?.grade
          }
        ), {}),
      },
      {
        title: t('Class'),
        type: 'string',
        field: 'user.userProfile.student.class',
        lookup: quizReportData?.reduce((map, obj) => (
          {
            ...map,
            [(obj.user.userProfile.student?.class) as string]: obj.user.userProfile.student?.class
          }
        ), {}),
      },
      {
        title: t('Subject'),
        type: 'string',
        field: 'subject',
        render: (rowData) => i18n.language === 'zh' ? `${rowData.subject.nameChi}` : `${rowData.subject.nameEng}`,
        exportTransformer: (rowData) => i18n.language === 'zh' ? rowData.subject.nameChi : rowData.subject.nameEng,
        customSort: (a, b) => (a.subject.nameEng).localeCompare(b.subject.nameEng),
      },
      {
        title: t('Learning Unit Area'),
        type: 'string',
        field: 'learningUnitArea._id',
        customSort: (a, b) => (a.learningUnitArea.nameEng).localeCompare(b.learningUnitArea.nameEng),
        lookup: quizReportData?.reduce((map, obj) => (
          {
            ...map,
            [obj.learningUnitArea._id]: (i18n.language === 'zh' && obj.learningUnitArea.nameChi !== null ? obj.learningUnitArea.nameChi : obj.learningUnitArea.nameEng)
          }
        ), {}),
        render: (rowData) => (i18n.language === 'zh' && rowData.learningUnitArea.nameChi !== null) ? `${rowData.learningUnitArea.nameChi}` : `${rowData.learningUnitArea.nameEng}`,
      },
      {
        title: t('Learning Unit'),
        type: 'string',
        field: 'learningUnit',
        customSort: (a, b) => (a.learningUnit.code).localeCompare(b.learningUnit.code),
        render: (rowData) => `${rowData.learningUnit.code} ${(i18n.language === 'zh' ? `${rowData.learningUnit.nameChi}` : `${rowData.learningUnit.nameEng}`)}`,
        exportTransformer: (rowData) => `${rowData.learningUnit.code} ${(i18n.language === 'zh' ? rowData.learningUnit.nameChi : rowData.learningUnit.nameEng)}`,
      },
      {
        title: t('Learning Objective'),
        type: 'string',
        field: 'learningObjective',
        customSort: (a, b) => (a.learningObjective.code).localeCompare(b.learningObjective.code),
        render: (rowData) => rowData.learningObjective.code + ' ' + ((i18n.language === 'zh' && rowData.learningObjective.nameChi !== null) ? `${rowData.learningObjective.nameChi}` : `${rowData.learningObjective.nameEng}`),
        exportTransformer: (rowData) => rowData.learningObjective.code + ' ' + ((i18n.language === 'zh' && rowData.learningObjective.nameChi !== null) ? rowData.learningObjective.nameChi : rowData.learningObjective.nameEng),
      },
      {
        title: t('No of Questions'),
        type: 'numeric',
        field: 'questions',
        customSort: (a, b) => a.questions.length - b.questions.length,
        render: (rowData) => rowData.questions.length,
        exportTransformer: (value) => {
          return value.questions.length
        },
      },
      {
        title: `${t('Correct')}`,
        type: 'string',
        field: 'questions',
        sorting: false,
        hidden: true,
        export: true,
        exportTransformer: (value) => value.questions.filter(x => x.correctAnswer === x.userAnswer).length,
      },
      {
        title: `${t('Incorrect')}`,
        type: 'string',
        field: 'questions',
        sorting: false,
        hidden: true,
        export: true,
        exportTransformer: (value) => value.questions.filter(x => x.correctAnswer !== x.userAnswer).length,
      },
      {
        title: `${t('Correct')}/${t('Incorrect')}`,
        type: 'string',
        sorting: false,
        export: false,
        render: (rowData) => (
          <HStack alignItems={'center'}>
            <Check style={{ color: colors.success['500'] }} />
            <Text color={colors.success['500']}>
              {
                rowData.questions.filter(
                  (x) => x.correctAnswer === x.userAnswer
                ).length
              }
            </Text>
            <Clear style={{ color: colors.error['500'] }} />
            <Text color={colors.error['500']}>
              {
                rowData.questions.filter(
                  (x) => x.correctAnswer !== x.userAnswer
                ).length
              }
            </Text>
          </HStack>
        ),
      },
      {
        title: t('Score'),
        type: 'numeric',
        field: 'score',
        exportTransformer: (rowData) => rowData.score * 100,
        render: (rowData) => {
          return (
            <Text
              fontSize={20}
              bold={true}
              color={
                rowData.score < 0.7
                  ? colors.error['500']
                  : colors.success['500']
              }
            >
              {(rowData.score * 100).toFixed(2)}
            </Text>
          );
        },
      },
      {
        title: t('Bonus'),
        type: 'numeric',
        field: 'bonus',
        render: (rowData) => {
          return (
            rowData.bonus ?
              (
                <HStack space={1}>
                  {

                    [...Array(rowData.bonus)].map(_ => (
                      <FaStar
                        size={20}
                        color={colors.primary['700']}
                      />
                    ))
                  }
                </HStack>
              ) :
              ""
          )
        },
      },
      {
        title: t('Time used'),
        type: 'numeric',
        field: 'elapsedTimeMs',
        exportTransformer: (rowData) => rowData.elapsedTimeMs / 1000,
        render: (rowData) => {
          const elapsedTime = rowData.elapsedTimeMs;
          let elapsedTimeDisplay = '';
          if (elapsedTime / 1000 / 60 / 60 > 1) {
            elapsedTimeDisplay = moment.utc(elapsedTime).format('HH:mm:ss');
          } else {
            elapsedTimeDisplay = moment.utc(elapsedTime).format('mm:ss');
          }
          return elapsedTimeDisplay
        }
      },
      {
        title: t('Attempted Date'),
        type: 'datetime',
        field: 'createdAt',
      },
    ]);
  }, [quizReportData, i18n.language]);

  const [filteredData, setFilteredData] = useState<any>([]);
  const [filters, setFilters] = useState<{
    student: any,
    grade: any[],
    class: any[],
    learningUnit: any[],
    learningObjective: any[],
  }>({
    student: undefined,
    grade: [],
    class: [],
    learningUnit: [],
    learningObjective: []
  });

  useEffect(() => {
    setFilteredData(quizReportData);
  }, [quizReportData])

  useEffect(() => {
    if (filters.student !== undefined) {
      setQueryUserId(filters.student.value);
      setIsShowOnlyTopScore(false)
    }
    const filterLength = Object.entries(filters).filter((key: any, value: any) => {
      return key !== 'student' || value.length > 0;
    })
    if (filterLength.length === 0) {
      setFilteredData(quizReportData);
    } else {
      const _filterData = quizReportData.filter((x) => {
        return (
          (filters.learningUnit.length === 0 || filters.learningUnit.map((x: any) => x.value).includes(x.learningUnit._id)) &&
          (filters.learningObjective.length === 0 || filters.learningObjective.map((x: any) => x.value).includes(x.learningObjective._id)) &&
          (filters.class.length === 0 || filters.class.map((x: any) => x.value).includes(x.user.userProfile.student?.class)) &&
          (filters.grade.length === 0 || filters.grade.map((x: any) => x.value).includes(x.user.userProfile.student?.grade))
        );
      })
      setFilteredData(_filterData);
    }
  }, [filters, quizReportData]);

  const { data: learningUnits } = useQuery('learningUnits', () => getLearningUnitsByStudentRole(), {
    refetchOnWindowFocus: false,
  });

  const { data: learningObjectives } = useQuery('learningObjectives', () => getLearningObjectivesByStudentRole(), {
    refetchOnWindowFocus: false,
  });

  const ReportRoute = () => (
    <>
      <Flex flexDirection={['column', 'row', 'row', 'row']} flex={1} margin={2} flexWrap={'wrap'} >
        {currentRole !== 'student' &&
          <Box w={['90%', '50%', '33%', '25%']}>
            <Select
              placeholder={t('Choose Primary')}
              styles={selectStyles}
              menuPortalTarget={document.body}
              isMulti
              value={filters.grade}
              onChange={(newValue) => handleSelectOnChange('grade', newValue)}
              options={quizReportData.map(x => x.user.userProfile.student?.grade).filter((value, index, self) => self.indexOf(value) === index).map(grade => (
                {
                  label: grade,
                  value: grade,
                }))}
            />
          </Box>
        }
        {currentRole !== 'student' &&
          <Box w={['90%', '50%', '33%', '25%']}>
            <Select
              placeholder={t('Choose Class')}
              styles={selectStyles}
              menuPortalTarget={document.body}
              isMulti
              value={filters.class}
              onChange={(newValue) => handleSelectOnChange('class', newValue)}
              options={quizReportData.map(x => x.user.userProfile.student?.class).filter((value, index, self) => self.indexOf(value) === index).map(_class => (
                {
                  label: _class,
                  value: _class,
                }))}
            />
          </Box>
        }
        <Box w={['90%', '50%', '33%', '25%']}>
          <Select
            placeholder={t('Choose Learning Unit')}
            styles={selectStyles}
            menuPortalTarget={document.body}
            isMulti
            value={filters.learningUnit}
            onChange={(newValue) => handleSelectOnChange('learningUnit', newValue)}
            options={learningUnits?.map((learningUnit) => ({
              ...learningUnit,
              label: `${learningUnit.code} - ${i18n.language === 'zh' && learningUnit.nameChi !== null ? learningUnit.nameChi : learningUnit.nameEng
                }`,
              value: learningUnit._id,
            }))}
          />
        </Box>
        <Box w={['90%', '50%', '33%', '25%']}>
          <Select
            placeholder={t('Choose Learning Objective')}
            styles={selectStyles}
            menuPortalTarget={document.body}
            isMulti
            value={filters.learningObjective}
            onChange={(newValue) => handleSelectOnChange('learningObjective', newValue)}
            options={learningObjectives?.map((learningObjective) => ({
              ...learningObjective,
              label: `${learningObjective.code} - ${i18n.language === 'zh' && learningObjective.nameChi !== null ? learningObjective.nameChi : learningObjective.nameEng
                }`,
              value: learningObjective._id,
            }))}
          />
        </Box>
      </Flex>

      <Center padding="10px">
        {filteredData && filteredData.length > 0 && (
          <Box flexDirection={['column', 'column', 'row']} w='full' flexWrap={'wrap'}>
            <StudentReportRadarChart quizReports={filteredData} />
            <StudentReportFrequencyBarChart quizReports={filteredData} />
            <StudentReportLearningUnitBarChart quizReports={filteredData} />
            <StudentReportLearningObjectiveBarChart quizReports={filteredData} />
          </Box>
        )}
        <MaterialTable
          icons={tableIcons}
          style={{ width: '100%' }}
          title={""}
          isLoading={isLoading}
          onRowClick={(event, rowData) => {
            setSelectedReport(rowData);
          }}
          columns={columnObject}
          data={filteredData}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 25, 50],
            actionsColumnIndex: 0,
            filtering: false,
            search: false,
            exportAllData: true,
            exportMenu: [
              {
                label: t('Export Report'),
                exportFunc: (cols, data) => {
                  const sortedData = data.sort((a: any, b: any) => {
                    if ((a['user.userProfile.student.grade'] ?? '') === (b['user.userProfile.student.grade'] ?? '')) {
                      if ((a['user.userProfile.student.class'] ?? '') === (b['user.userProfile.student.class'] ?? '')) {
                        return (a['user.userProfile.student.number'] ?? 999) - (b['user.userProfile.student.number'] ?? 999);
                      }
                      return (a['user.userProfile.student.class'] ?? '').toString().localeCompare((b['user.userProfile.student.class'] ?? '').toString()) ?? 0;
                    }
                    return (a['user.userProfile.student.grade'] ?? '') - (b['user.userProfile.student.grade'] ?? '');

                  });
                  return ExportCsv(cols, sortedData, `Quiz Result Export ${dayjs().format('YYYYMMDDHHmmss')}`);
                },
              },
            ],
          }}
        />
      </Center>
    </>
  );

  return (
    <ScrollView paddingTop={["35px", '0']}>
      {selectedReport ? (
        <QuizReportModal
          onClose={() => setSelectedReport(undefined)}
          {...selectedReport}
        />
      ) : (
        <>
          <Heading>{isShowSummary ? t("Summary") : t("Reports")}</Heading>

          <Box w={['90%', '50%', '33%', '25%']}>
            {currentRole !== 'student' && !isShowSummary &&
              <>
                {studentOptions !== undefined ?
                  <HStack space={2} marginLeft={2}>
                    <Select
                      placeholder={t('Choose Student')}
                      styles={selectStyles}
                      menuPortalTarget={document.body}
                      value={filters.student}
                      onChange={(newValue) => setQueryUserId(newValue!.value)}
                      options={[
                        ...[{ label: t('Show all'), value: '' }],
                        ...studentOptions]}
                    />
                    <Button variant="outline" onPress={() => setIsShowSummary(true)}>
                      {t("Summary")}
                    </Button>
                  </HStack>
                  :
                  <HStack space={2} marginLeft={2}>
                    <Spinner accessibilityLabel="Loading students" />
                    <Heading color="primary.500" fontSize="md">
                      Loading Student
                    </Heading>
                  </HStack>
                }
              </>
            }
          </Box>

          {isShowSummary ? (
            <TeacherSummaryTab
              allData={summaryData}
              handleBackToReport={handleBackToReport}
            />
          ) : (
            <ReportRoute />
          )}
        </>
      )}
    </ScrollView>
  );
};

export default StudentReportPage;
