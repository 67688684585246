import React, { useState, useRef } from "react";
import {
  Heading,
  Center,
  useToast,
  Button,
  Text,
  Alert,
  CloseIcon,
  HStack,
  IconButton,
  VStack,
  Box,
  ScrollView,
} from "native-base";
import MaterialTable, { MTableToolbar } from "@material-table/core";
import { Edit, AddBox, Check, Clear } from "@material-ui/icons";
import { useQuery } from "react-query";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { FaFileUpload } from "react-icons/fa";
import FileSaver from "file-saver";
import { ExportCsv } from "@material-table/exporters";
import { RootState } from "../redux/reducers";
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { tableIcons } from "../MaterialTableIcons";
import { getUsers, adminDeleteUser, getSchools } from "../api";
import UserModal from "./Management/User/UserModal";
import { IUser } from "../types/AuthTypes";
import StudentCsvUploadPreviewModal from "../components/StudentCsvUploadPreviewModal";
import TeacherCsvUploadPreviewModal from "../components/TeacherCsvUploadPreviewModal";
import SysAdminCsvUploadPreviewModal from "../components/SysAdminCsvUploadPreviewModal";
import ContentProviderCsvUploadPreviewModal from "../components/ContentProviderCsvUploadPreviewModal";
import i18n from "../i18n/i18n";

const mapStateToProps = (state: RootState) => ({
  user: state.auth.user,
  currentRole: state.auth.currentRole,
});

const connector = connect(mapStateToProps);
type PropsType = ConnectedProps<typeof connector> & RouteComponentProps;

const UserManagementPage: React.FC<PropsType> = (props) => {
  const [modalStatus, setModalStatus] = useState<string>("");
  const [isModalShown, setIsModalShown] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<IUser>();
  const [isPreviewModalShownStudents, setIsPreviewModalShownStudents] =
    useState<boolean>(false);
  const [isPreviewModalShownTeachers, setIsPreviewModalShownTeachers] =
    useState<boolean>(false);
  const [isPreviewModalShownSysAdmins, setIsPreviewModalShownSysAdmins] =
    useState<boolean>(false);
  const [isPreviewModalShownContentProviders, setIsPreviewModalShownContentProviders] =
    useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const [error, setError] = useState<string>("");

  const refStudents = useRef<HTMLInputElement>(null);
  const refTeachers = useRef<HTMLInputElement>(null);
  const refSysAdmins = useRef<HTMLInputElement>(null);
  const refContentProvider = useRef<HTMLInputElement>(null);
  const toast = useToast();
  const { t } = useTranslation();

  const {
    data: Users,
    refetch,
    isFetching,
  } = useQuery("Users", getUsers, {
    refetchOnWindowFocus: false,
    onError: () => {
      toast.show({
        title: t("Something went wrong"),
        status: "error",
        description: t("Cannot fetch Users"),
      });
    },
  });

  const { data: schools } = useQuery('schools', () => getSchools());

  const handleAddOnClick = () => {
    setModalStatus("create");
    setIsModalShown(true);
  };

  const handleEditOnClick = (rowData: any) => {
    setSelectedUser(rowData);
    setModalStatus("edit");
    setIsModalShown(true);
  };

  const handleModalOnClose = () => {
    setSelectedUser(undefined);
    setIsModalShown(false);
  };

  const handleCsvOnImport = async (event: any, type: string) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      switch (type) {
        case "students":
          setIsPreviewModalShownStudents(true);
          break;
        case "teachers":
          setIsPreviewModalShownTeachers(true);
          break;
        case "sysadmins":
          setIsPreviewModalShownSysAdmins(true);
          break;
        case "contenProviders":
        default:
          setIsPreviewModalShownContentProviders(true);
          break;
      }
    }
    event.target.value = null;
  };

  const getGenderCharacter = (gender: string) => {
    switch (gender?.toString().toLowerCase()) {
      case "female":
      case "Female":
        return "F";
      case "male":
      case "Male":
        return "M";
      default:
        return "";
    }
  };

  const handleSubmitOnSuccess = (data?: any) => {
    setSelectedUser(undefined);
    setIsModalShown(false);
    switch (modalStatus) {
      case "create":
        refetch();
        toast.show({
          title: t("A new user has been created successfully"),
          status: "success",
        });
        break;
      case "edit":
      default:
        refetch();
        toast.show({
          title: t("A user has been updated successfully"),
          status: "success",
        });
        break;
    }
  };

  const roleRender = (rowData: any) => {
    let roles: string[] = [];
    let rolesDisplay = new Map(Object.entries({
      student: t('Student'),
      teacher: t('Teacher'), 
      systemAdmin: t('System Admin'), 
      contentProvider: t('Content Provider'), 
    }));
    Object.entries(rowData.userProfile).map(([key, value]) => {
      if ((value as any).isActive === true && key !== undefined) {
        let role = rolesDisplay.get(key);
        if (role !== undefined){
          roles = [...roles, role];
        }
      }
    });
    if (roles.length === 0) {
      roles.push(t('No Role Found'));
    }
    return roles;
  }

  const schoolRender = (rowData: any) => {
    let results: string[] = [];
    let studentSchool = schools?.find((school) => 
      school._id === rowData.userProfile?.student?.school
    );
    let teacherSchool = schools?.find((school) => 
      school._id === rowData.userProfile?.teacher?.school
    );
    if (i18n.language === 'zh' && studentSchool?.nameChi !== null && studentSchool?.nameChi !== undefined) {
      results.push(studentSchool?.nameChi)
    } else if (studentSchool?.nameEng !== null && studentSchool?.nameEng !== undefined){
      results.push(studentSchool?.nameEng)
    }
    // In case the student role and the teacher role are in the different school
    if (studentSchool !== teacherSchool) {
      if (i18n.language === 'zh' && teacherSchool?.nameChi !== null && teacherSchool?.nameChi !== undefined) {
        results.push(teacherSchool?.nameChi)
      } else if (teacherSchool?.nameEng !== null && teacherSchool?.nameEng !== undefined){
        results.push(teacherSchool?.nameEng)
      }
    }
    // Message for the user does not belong a school
    if (results.length === 0) {
      results.push(t('No School Found'));
    }
    return results;
  }

  const exportCsvByRole = (cols: any, data: any, role: string) => {
    let rolesDisplay = new Map(Object.entries({
      student: t('Student'),
      teacher: t('Teacher'), 
      systemAdmin: t('System Admin'), 
      contentProvider: t('Content Provider'), 
    }));
    // Include the User Id header for export
    cols.unshift({
      field: "userId",
      title: `${t('User')} ID`,
      type: "string"
    });
    cols.push({
      field: "gender",
      title: t("Gender"),
      type: "string"
    })
    // Remove the school field from the table
    cols.splice(10, 1);
    // Add field to the csv
    switch (role) {
      case "student":
        cols.push({
            field: "studentId",
            title: `${t('Student')} ID`,
            type: "string"
          }, {
            field: "school",
            title: t("School"),
            type: "string"
          }, {
            field: "grade",
            title: t("Grade"),
            type: "number"
          }, {
            field: "class",
            title: t("Class"),
            type: "string"
          }, {
            field: "number",
            title: t("Student Number"),
            type: "number"
          }, {
            field: "yearOfEntry",
            title: t("Year of Entry"),
            type: "number"
          }
        );
        break;
      case "teacher":
        cols.push({
            field: "school",
            title: `${t('School')} Id`,
            type: "string"
          }
        );
        break;
    }
    data.forEach((item: any) => {
      let roles: string[] = [];
      let target = Users?.data.findIndex((user: IUser) => user.email === item.email);
      // Include UserID in the CSV
      item.userId = Users?.data[target]._id;
      // For is active
      item.disabled = !item.disabled;
      item.gender = Users?.data[target].gender;

      // Parse the roles into string
      Object.entries(item.userProfile).map(([key, value]) => {
        if ((value as any).isActive === true && key !== undefined) {
          let role = rolesDisplay.get(key);
          if (role !== undefined){
            roles = [...roles, role];
          }
        }
      });
      item.userProfile = roles.join(", ");
      item.roles = roles

      switch (role) {
        case 'student':
          if (roles.includes(rolesDisplay.get(role) ?? '')) {
            item.school = Users?.data[target].userProfile.student.school;
            item.studentId = Users?.data[target].userProfile.student.studentId;
            item.grade = Users?.data[target].userProfile.student.grade;
            item.class = Users?.data[target].userProfile.student.class;
            item.number = Users?.data[target].userProfile.student.number;
            item.yearOfEntry = Users?.data[target].userProfile.student.yearOfEntry;
          }
          break;
        case 'teacher':
          if (roles.includes(rolesDisplay.get(role) ?? '')) {
            item.school = Users?.data[target].userProfile.teacher.school;
          }
          break;
      }
      return item;
    })
    data = data.filter((item: any) => item.roles.includes(rolesDisplay.get(role)));
    return ExportCsv(
      cols,
      data,
      `Export_${role}_${moment().format("YYYYMMDDHHmmss")}`
    );
  }

  return (
    <ScrollView paddingTop={["35px", "0"]}>
      <Heading>{t("User Management")}</Heading>

      <Center padding="10px">
        <MaterialTable
          editable={{
            onRowDelete: async (oldData) => {
              try {
                await adminDeleteUser(oldData._id)
                toast.show({
                  title: t("A user has been deleted successfully"),
                  status: "success",
                });
                refetch()
              } catch (error: any) {
                setError(error.toString());
              }
            },
            isDeletable: rowData => rowData._id !== props.user?._id,
          }}
          actions={[
            {
              icon: () => <AddBox />,
              tooltip: t("Add User"),
              isFreeAction: true,
              onClick: (event) => handleAddOnClick(),
            },
            {
              icon: () => <Edit />,
              tooltip: t("Edit Details"),
              onClick: (event, rowData) => handleEditOnClick(rowData),
            },
            {
              icon: () => <FaFileUpload />,
              tooltip: t("Import students from Excel/CSV"),
              isFreeAction: true,
              onClick: (event) => refStudents.current?.click(),
            },
            {
              icon: () => <FaFileUpload />,
              tooltip: t("Import teachers from Excel/CSV"),
              isFreeAction: true,
              onClick: (event) => refTeachers.current?.click(),
            },
            {
              icon: () => <FaFileUpload />,
              tooltip: t("Import system admins from Excel/CSV"),
              isFreeAction: true,
              onClick: (event) => refSysAdmins.current?.click(),
            },
            {
              icon: () => <FaFileUpload />,
              tooltip: t("Import content providers from Excel/CSV"),
              isFreeAction: true,
              onClick: (event) => refContentProvider.current?.click(),
            },
          ]}
          icons={tableIcons}
          style={{ width: "100%" }}
          title={t("Users")}
          isLoading={isFetching}
          columns={[
            {
              title: "db id",
              type: "string",
              field: "_id",
              editable: "never",
              hidden: true,
            },
            {
              title: `${t("User")} ID`,
              type: "string",
              field: "userId",
              editable: "never",
              hidden: true,
            },
            {
              title: t("Email"),
              type: "string",
              field: "email",
            },
            {
              title: t("Email Verified"),
              type: "boolean",
              field: "emailVerified",
              render: (rowData) =>
                rowData.emailVerified ? <Check /> : <Clear />,
            },
            {
              title: `${t("First Name")} (${t("Eng")})`,
              type: "string",
              field: "firstNameEng",
            },
            {
              title: `${t("Last Name")} (${t("Eng")})`,
              type: "string",
              field: "lastNameEng",
            },
            {
              title: `${t("First Name")} (${t("Chi")})`,
              type: "string",
              field: "firstNameChi",
            },
            {
              title: `${t("Last Name")} (${t("Chi")})`,
              type: "string",
              field: "lastNameChi",
            },
            {
              title: t("Role"),
              type: "string",
              field: "userProfile",
              // editable: 'never',
              render: (rowData) => {
                return roleRender(rowData).join(", ");
              },
              editComponent: (props) => (
                <Button variant={"outline"}>{t("Edit Role")}</Button>
              ),
              customFilterAndSearch: (term, rowData) => {
                return (roleRender(rowData).join(", ").toLowerCase().search(term.toLowerCase()) !== -1)
              }
            },
            {
              title: t("Date of Birth"),
              type: "string",
              field: "dateOfBirth",
              render: (rowData) => {
                return (rowData.dateOfBirth === null ? "" : moment(rowData.dateOfBirth).format("YYYY-MM-DD"));
              },
            },
            {
              title: t('Active'),
              type: "boolean",
              field: "disabled",
              render: (rowData) => (rowData.disabled ? <Clear /> : <Check />),
            },
            {
              title: t('School'),
              type: "string",
              render: (rowData) => {
                return schoolRender(rowData).join(', ');
              },
              customFilterAndSearch: (term, rowData) => {
                return (schoolRender(rowData).join(', ').toLowerCase().search(term.toLowerCase()) !== -1)
              }
            }
          ]}
          data={Users?.data}
          components={{
            Toolbar: (props) => (
              <>
                <MTableToolbar {...props} />
                {error && (
                  <Box justifyContent="center" alignItems="center">
                    <Alert w="100%" status="error" borderRadius={0}>
                      <VStack space={2} flexShrink={1} w="100%">
                        <HStack
                          flexShrink={1}
                          space={2}
                          justifyContent="space-between"
                        >
                          <HStack space={2} flexShrink={1}>
                            <Alert.Icon mt="1" />
                            <Text fontSize="md" color="coolGray.800">
                              {error}
                            </Text>
                          </HStack>
                          <IconButton
                            onPress={() => setError("")}
                            variant="unstyled"
                            icon={<CloseIcon size="3" color="coolGray.600" />}
                          />
                        </HStack>
                      </VStack>
                    </Alert>
                  </Box>
                )}
              </>
            ),
          }}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 20, 30],
            filtering: true,
            addRowPosition: "first",
            actionsColumnIndex: 0,
            exportAllData: true,
            exportMenu: [
              {
                label: t("Export all students to CSV"),
                exportFunc: (cols, data) => {
                  exportCsvByRole(cols, data, "student")
                }
              }, 
              {
                label: t("Export all teachers to CSV"),
                exportFunc: (cols, data) => {
                  exportCsvByRole(cols, data, "teacher")
                }
              },
              {
                label: t("Export all system admins to CSV"),
                exportFunc: (cols, data) => {
                  exportCsvByRole(cols, data, "systemAdmin")
                }
              },
              {
                label: t("Export all content providers to CSV"),
                exportFunc: (cols, data) => {
                  exportCsvByRole(cols, data, "contentProvider")
                }
              },
              {
                label: t("Download student template for import"),
                exportFunc: () => {
                  return FileSaver.saveAs(
                    "/templates/import_student_template.csv",
                    "student_template.csv"
                  );
                },
              },
              {
                label: t("Download teacher template for import"),
                exportFunc: () => {
                  return FileSaver.saveAs(
                    "/templates/import_teacher_template.csv",
                    "teacher_template.csv"
                  );
                },
              },
              {
                label: t("Download system admin template for import"),
                exportFunc: () => {
                  return FileSaver.saveAs(
                    "/templates/import_sysadmin_template.csv",
                    "sysadmin_template.csv"
                  );
                },
              },
              {
                label: t("Download content provider template for import"),
                exportFunc: () => {
                  return FileSaver.saveAs(
                    "/templates/import_contentProvider_templat.csv",
                    "contentProvider_template.csv"
                  );
                },
              },
            ],
          }}
        />
      </Center>
      {isModalShown && (
        <UserModal
          status={modalStatus}
          onClose={handleModalOnClose}
          onEditClick={() => setModalStatus("edit")}
          onSubmitSuccess={handleSubmitOnSuccess}
          data={selectedUser}
        />
      )}
      <input
        ref={refStudents}
        type="file"
        style={{ display: "none" }}
        accept=".csv, .xls, .xlsx"
        onChange={(e) => handleCsvOnImport(e, "students")}
      />
      <input
        ref={refTeachers}
        type="file"
        style={{ display: "none" }}
        accept=".csv, .xls, .xlsx"
        onChange={(e) => handleCsvOnImport(e, "teachers")}
      />
      <input
        ref={refSysAdmins}
        type="file"
        style={{ display: "none" }}
        accept=".csv, .xls, .xlsx"
        onChange={(e) => handleCsvOnImport(e, "sysadmins")}
      />
      <input
        ref={refContentProvider}
        type="file"
        style={{ display: "none" }}
        accept=".csv, .xls, .xlsx"
        onChange={(e) => handleCsvOnImport(e, "contenProviders")}
      />
      {isPreviewModalShownStudents && (
        <StudentCsvUploadPreviewModal
          file={file}
          onClose={() => setIsPreviewModalShownStudents(false)}
          onSubmitSuccess={handleSubmitOnSuccess}
        />
      )}
      {isPreviewModalShownTeachers && (
        <TeacherCsvUploadPreviewModal
          file={file}
          onClose={() => setIsPreviewModalShownTeachers(false)}
          onSubmitSuccess={handleSubmitOnSuccess}
        />
      )}
      {isPreviewModalShownSysAdmins && (
        <SysAdminCsvUploadPreviewModal
          file={file}
          onClose={() => setIsPreviewModalShownSysAdmins(false)}
          onSubmitSuccess={handleSubmitOnSuccess}
        />
      )}
      {isPreviewModalShownContentProviders && (
        <ContentProviderCsvUploadPreviewModal
          file={file}
          onClose={() => setIsPreviewModalShownContentProviders(false)}
          onSubmitSuccess={handleSubmitOnSuccess}
        />
      )}
    </ScrollView>
  );
};

export default withRouter(connector(UserManagementPage));;
