import moment from 'moment';
import { Box, Heading } from 'native-base';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoFileTray } from 'react-icons/io5';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  ResponsiveContainer,
} from 'recharts';
import { IQuiz } from '../types/QuizTypes';
import { barColors } from './StudentReportLearningObjectiveBarChart'

interface StudentReportLearningUnitBarChartProps {
  quizReports: IQuiz[];
}

const StudentReportLearningUnitBarChart: React.FC<StudentReportLearningUnitBarChartProps> = (props) => {
  const [data, setData] = useState<any[]>();
  const [dataKeySet, setDataKeySet] = useState<{ [key: string]: string }>();
  const { t } = useTranslation();


  useEffect(() => {
    // group scores with its learning unit by date
    const dataGroupByDate = props.quizReports.sort((a, b) => (a.learningUnit.code < b.learningUnit.code ? -1 : 1)).reduce<{ [key: string]: any[] }>(
      (groups, item) => {
        const date = moment(item.createdAt).format('L');
        return {
          ...groups,
          [date]: [
            ...(groups[date] || []),
            { [item.learningUnit.code]: item.score * 100 },
          ],
        };
      },
      {}
    );

    // filter out duplicated and non-max score for each learning unit for each date
    let formattedData: any = [];
    Object.keys(dataGroupByDate).forEach((k) => {
      let unitMap: any = {};
      dataGroupByDate[k].forEach((LUScoresMap) => {
        const unit = Object.keys(LUScoresMap)[0];
        if (unitMap[unit] && unitMap[unit] < LUScoresMap[unit]) {
          unitMap[unit] = LUScoresMap[unit];
        }
        if (!unitMap[unit]) {
          unitMap[unit] = LUScoresMap[unit];
        }
      });
      formattedData.push({ createdAt: k, ...unitMap });
    });

    setData(formattedData.sort((a: any, b: any) => a.createdAt < b.createdAt ? -1 : 1));
    console.log(formattedData)

    //get all LU with quizzes and generate unique color for them
    const colorKeys: any = {};
    props.quizReports.map(x => x.learningUnit.code).filter((v, i, a) => a.indexOf(v) === i).forEach((code, i) => {
      colorKeys[code] = barColors[i];
    });
    console.log(Object.values(colorKeys))
    setDataKeySet(colorKeys);
  }, [props.quizReports]);

  return (
    <Box w={"100%"} h="300px" mb={8}>
      <Heading>{t('Learning Unit')}</Heading>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="createdAt" />
          <YAxis>
            <Label value={t("Score")} angle={-90} dx={-15} />
          </YAxis>
          <Tooltip />
          <Legend verticalAlign="bottom" />
          {dataKeySet &&
            Object.keys(dataKeySet).map((key, index) => (
              <Bar key={key} dataKey={key} fill={`${dataKeySet[key]}`} />
            ))}
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default StudentReportLearningUnitBarChart;
